<template>
  <HeaderSection />
  <main class="page-content">
    <UploadImage />
  </main>
</template>
<script>
import UploadImage from "../components/NewUser/UploadImage.vue";
import HeaderSection from "../components/Header/HeaderSection.vue";
export default {
  name: "ImageUpload",
  components: {
    UploadImage,
    HeaderSection,
  },
};
</script>
